<template>
    <div class="person-form-container">

        <!-- Pagination Component -->
        <!-- <Pagination v-if="paginationPeople.total > 0" :config="paginationPeople" @search="onPageChangePeople"
            class="pagination-status" /> -->

        <!-- People Display Section -->
        <!-- <div class="people-list">
            <h2>People</h2>
            <div class="row">
                <div class="col-md-4 mb-3" v-for="person in people" :key="person.id">
                    <div class="card" :class="{ 'selected-card': isSelected(person.id) }" @click="selectPerson(person)">
                        <img :src="getPersonImage(person)" alt="Person Picture" class="card-img-top">
                        <div class="card-body">
                            <h5 class="card-title">{{ person.name }}</h5>
                            <p class="card-text">{{ person.description }}</p>
                            <small class="text-muted">Created at: {{ formatDateTimeISO(person.created_at) }}</small>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- Buttons for different actions -->
        <!-- <div class="d-flex justify-content-around mt-3">
                <button type="button" class="btn btn-primary me-2" @click="Continue">Continue</button>
            </div>
        </div> -->

        <!-- Section to Display Names of Persons Added -->
        <div class="added-persons mt-4" v-if="pendingPersons.length">
            <h2 class="mb-3">Persons Added:</h2>
            <ul class="list-group">
                <li v-for="pendingPerson in pendingPersons" :key="pendingPerson.id" class="list-group-item">
                    <strong>Name:</strong> {{ pendingPerson.name || 'N/A' }},
                    <strong>Description:</strong> {{ pendingPerson.description || 'No description' }}
                </li>
            </ul>
        </div>

        <!-- Form for Creating a New Person -->
        <div class="create-person-form">
            <h2>Create New Person</h2>
            <form @submit.prevent="createPerson">
                <div class="mb-3">
                    <label for="personName" class="form-label">Name:</label>
                    <input type="text" id="personName" v-model="newPerson.name" class="form-control">
                </div>
                <div class="mb-3">
                    <label for="personDescription" class="form-label">Description:<span class="text-danger">*</span></label>
                    <textarea id="personDescription" v-model="newPerson.description" class="form-control"></textarea>
                </div>
                <div class="mb-3">
                    <label for="personFace" class="form-label">Choose Face:<span class="text-danger">*</span></label>
                    <select id="personFace" @click="fetchFaces(1)" v-model="newPerson.faces" class="form-control"
                        :class="{ 'selected-face': newPerson.faces, 'unselected-face': !newPerson.faces }">
                    </select>
                </div>

                <!-- Display face selection status -->
                <div class="mb-3">
                    <span v-if="newPerson.faces">{{ getFaceStatus(newPerson.faces) }}</span>
                    <span v-else>No face selected</span>
                </div>

                <!-- Buttons for different actions -->
                <div class="d-flex justify-content-around mt-3">
                    <button type="button" class="btn btn-primary me-2" @click="createPersonAndContinue">Create and
                        Continue</button>

                    <button type="button" class="btn btn-secondary" @click="clearNewPerson">Clear Person Form</button>
                    <!-- Two buttons related to having more than one person on a incident form currently not in use as one person per incident -->
                    <!-- <button type="button" class="btn btn-secondary" @click="createPersonAndAddAnother">Create and Add
                        Another</button> -->
                    <!-- <button type="button" class="btn btn-secondary" @click="clearPersonsAdded">Clear Persons Added</button> -->
                </div>
            </form>
        </div>

        <!-- Pagination Component -->
        <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" class="pagination-status" />

        <!-- Faces Display Section -->
        <div class="faces-gallery mt-4 row" v-if="faces.length">
            <div v-for="face in faces" :key="face.id" class="face-item col-sm-6 col-md-4 col-lg-3 mb-4"
                @click="selectFace(face.id)" :class="{ 'selected-card': face.id === newPerson.faces }">
                <div class="card">
                    <img :src="face.sas" alt="Face" class="card-img-top" />
                    <div class="card-body">
                        <!-- Face details -->
                        <p class="card-text"><strong>Date and Time:</strong> {{ formatDateTime(face.created_at) }}</p>
                        <p class="card-text"><strong>Age Range:</strong> {{ formatAgeRange(face.age_min, face.age_max) }}
                        </p>
                        <!-- Add other details as needed -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Pagination Component -->
        <Pagination v-if="pagination.total > 0" :config="pagination" @search="onPageChange" class="pagination-status" />
    </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../../components/Pagination.vue';
import { v4 as uuidv4 } from 'uuid';
import authHeader from "../../services/auth-header";
import { globalState } from './globalState.js';

export default {
    name: 'PersonForm',
    props: {
        faceId: String, page: Number, dateFilter: String
    },
    components: {
        Pagination,
    },
    data() {
        return {
            people: [], // List of people
            selectedPersonIds: [],
            selectedPeople: [],
            faces: [], // List of faces
            newPerson: { // Model for new person
                name: '',
                description: '',
                faces: this.$route.params.faceId || '',
            },
            pendingPersons: [], // Array to store pending persons
            pagination: { // Pagination data
                page: 1,
                pageSize: 25,
                total: 0,
                maxPage: 0,
            },
            paginationPeople: { // Pagination data
                page: 1,
                pageSize: 3,
                total: 0,
                maxPage: 0,
            },
            cameraIdFilter: '',
            siteIdFilter: null,
            faceIdFilter: '',
            startFilter: '',
            endFilter: '',
            // Date range filter
            startDate: '',
            endDate: '',
            //
            selectedFaceId: null,
            propFaceId: '',
            claim: {
                site: null,
                postcode: null,
                comments: null,
                fuels: [
                    {
                        id: Math.floor((Math.random() * 10) + 1),
                        type: "",
                        value: null,
                        litres: null,
                        pump: null
                    }
                ],
                evidence: [
                    {
                        id: uuidv4(),
                        file: null,
                        type: "vehicle_image",
                        comments: null,
                        error: false,
                        errorMsg: "",
                        permanent: true,
                        src: null,
                        isPdf: false
                    },
                    {
                        id: uuidv4(),
                        file: null,
                        type: "till_receipt",
                        comments: null,
                        error: false,
                        errorMsg: "",
                        permanent: true,
                        src: null,
                        isPdf: false
                    }
                ],
                accept_agreement: false,
                date: null,
                time: null,
            },
        };
    },

    computed: {
        siteId() {
            console.log(`COMING FROM COMPUTED SITE ID`, globalState.selectedSiteId);
            return globalState.selectedSiteId;
        }
    },

    watch: {
        siteId(newSiteId, oldSiteId) {
            if (newSiteId !== oldSiteId) {
                this.fetchIncidents(); // Update your incidents based on the new siteId
            }
        }
    },

    async created() {
        // this.siteIdFilter = this.siteId;
        this.siteIdFilter = localStorage.getItem('selectedSiteId');
        await this.fetchPeople();

        if (this.$route.params.page) {
            await this.fetchFaces(this.$route.params.page);
        } else {
            this.fetchFaces();
        }
    },

    methods: {
        async fetchPeople(page = 1) {
            this.paginationPeople.page = page;

            const params = {
                site_id: this.siteIdFilter, // Compulsory
                page: this.paginationPeople.page,
                count: this.paginationPeople.pageSize
            };

            // Prepare custom headers
            // const headers = {
            //     'x-vars-claims': '{"id":4,"permissions":["people.read", "incident.read", "incident.create", "faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
            // };

            try {
                const response = await axios.get("https://api.varsanpr.com/customer/faces/people", {
                    params,
                    headers: authHeader()
                });
                console.log(response.data);
                this.people = response.data.people;
                console.log(this.people);
                this.people.forEach(person => {
                    if (person.faces && person.faces.length > 0) {
                        person.pictureUrl = person.faces[0].background_sas;
                    }
                });
                // Update pagination data based on API response
                this.paginationPeople.total = response.data.pagination.total;
                this.paginationPeople.maxPage = Math.ceil(this.paginationPeople.total / this.paginationPeople.pageSize);
            } catch (error) {
                console.error('Error fetching people:', error);
                // Handle errors appropriately
            }
        },

        getPersonImage(person) {
            // Return the first face's background_sas URL or a default image
            const imageUrl = person.pictureUrl || 'path_to_default_image.jpg';
            console.log(`Image URL for person ${person.id}: ${imageUrl}`);
            return imageUrl;
        },

        createPersonAndContinue() {
            if (this.newPerson.name || this.newPerson.description || this.newPerson.faces) {
                if (!this.validateForm()) {
                    return;
                }

                // Find the selected face details
                const selectedFace = this.faces.find(face => face.id === this.newPerson.faces);
                const faceImageUrl = selectedFace ? selectedFace.background_sas : ''

                this.pendingPersons.push({
                    ...this.newPerson,
                    imageUrl: faceImageUrl // Add the image URL
                }); // Save the new person if form is filled
                this.clearNewPerson(); // Clear the form after adding the person
            }

            if (this.pendingPersons.length > 0) {
                // Navigate to "IncidentForm" with pendingPersons
                this.$router.push({
                    name: 'Form', // Ensure this name matches your router configuration
                    params: { pendingPersons: JSON.stringify(this.pendingPersons), dateFilter: this.dateFilter },
                });
            } else {
                this.$error('Please add at least one person before continuing.');
            }
        },

        clearNewPerson() {
            this.newPerson = {
                name: '',
                description: '',
                faces: '',
            };
        },

        async fetchFaces(page = 1) {
            // Update current page
            this.pagination.page = page;

            const params = {
                camera_id: this.cameraIdFilter,
                site_id: this.siteIdFilter, // Compulsory
                face_id: this.faceIdFilter,
                start: this.startFilter,   // Assuming you have a startFilter data property for start date
                end: this.endFilter,
                page: this.pagination.page,
                count: this.pagination.pageSize
            };

            // Construct start and end date-time strings
            if (this.startDate && this.endDate) {
                const formattedStartDate = new Date(this.startDate);
                const formattedEndDate = new Date(this.endDate);

                const startYear = formattedStartDate.getFullYear();
                const startMonth = String(formattedStartDate.getMonth() + 1).padStart(2, '0');
                const startDay = String(formattedStartDate.getDate()).padStart(2, '0');
                const endYear = formattedEndDate.getFullYear();
                const endMonth = String(formattedEndDate.getMonth() + 1).padStart(2, '0');
                const endDay = String(formattedEndDate.getDate()).padStart(2, '0');

                params.start = `${startYear}-${startMonth}-${startDay}`;
                params.end = `${endYear}-${endMonth}-${endDay}`;

                console.log(params.start);
                console.log(params.end);
            }

            // Prepare custom headers
            // const headers = {
            //     'x-vars-claims': '{"id":4,"permissions":["faces.read", "sites.read", "events-blacklist", "cameras-edit", "cameras-view", "sites-map", "logs-view", "staging-manage","users-edit", "claims-process","claims-view", "clients-edit", "accounting-view", "roles-view", "roles-edit", "permissions-view", "sites-view", "reports-view", "reports-claims-view", "nmop-view", "driveoff-view", "cnaf-view", "parking-view", "scheduler-view", "scheduler-delete", "tablets-view", "scheduler-create", "calls-view", "claims-manage-team"]}',
            // };

            try {
                const response = await axios.get("https://api.varsanpr.com/customer/faces/faces", {
                    params,
                    headers: authHeader()
                });
                this.faces = response.data.faces;
                console.log(response.data);
                // Update pagination data based on API response
                this.pagination.total = response.data.pagination.total;
                this.pagination.maxPage = Math.ceil(this.pagination.total / this.pagination.pageSize);
                console.log("PAGINATION TOTAL", this.pagination.total);
                console.log("PAGINATION MAXPAGE", this.pagination.maxPage)
            } catch (error) {
                console.error("Error fetching faces:", error);
            }
        },

        // Method to handle page change in pagination
        onPageChange(newPage) {
            this.fetchFaces(newPage);
        },

        onPageChangePeople(newPage) {
            this.fetchPeople(newPage);
        },

        formatDateTime(timestamp) {
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            return date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
        },

        formatDateTimeISO(isoString) {
            // Parses the ISO string into a Date object and formats it
            const date = new Date(isoString);
            return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' }) +
                ' ' +
                date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
        },

        formatAgeRange(min, max) {
            return `${min} - ${max}`;
        },

        selectFace(faceId) {
            // Set the selected faceId to the newPerson
            this.newPerson.faces = faceId;
        },

        validateForm() {
            //name validation currently not in use

            // if (!this.newPerson.name.trim()) {
            //     this.$error('Name is required');
            //     return false;
            // }

            if (!this.newPerson.description.trim()) {
                this.$error('Description is required');
                return false;
            }
            if (!this.newPerson.faces) {
                this.$error('Face is required');
                return false;
            }
            return true;
        },

        getFaceStatus(faceId) {
            const face = this.faces.find(f => f.id === faceId);
            return face ? "Face selected" : "No face selected";
        },

        selectPerson(person) {
            this.selectedPeople = [{
                id: person.id,
                name: person.name,
                description: person.description,
                imageUrl: person.pictureUrl
            }];
            console.log('Selected People:', this.selectedPeople);
        },

        Continue() {
            if (this.selectedPeople.length > 0) {
                const selectedPeopleParam = JSON.stringify(this.selectedPeople);
                this.$router.push({ name: 'Form', params: { personIds: selectedPeopleParam } });
            } else {
                this.$error('Please select at least one person before continuing.');
            }
        },

        isSelected(personId) {
            return this.selectedPeople.some(p => p.id === personId);
        },

        //Functions related to adding more than one person in the form but currently its one person per incident so need to be implemented for now

        // createPersonAndAddAnother() {
        //     if (!this.validateForm()) {
        //         return;
        //     }

        //     // Find the selected face details
        //     const selectedFace = this.faces.find(face => face.id === this.newPerson.faces);
        //     console.log('Selected Face:', selectedFace);
        //     const faceImageUrl = selectedFace ? selectedFace.background_sas : '' // Include the image URL

        //     this.pendingPersons.push({
        //         ...this.newPerson,
        //         imageUrl: faceImageUrl // Add the image URL
        //     }); // Save the pending person
        //     console.log('Pending Persons:', this.pendingPersons);
        //     console.log('New Person:', this.newPerson);
        //     this.clearNewPerson(); // Clear the form
        // },

        // clearPersonsAdded() {
        //     this.pendingPersons = [];
        // },

        // selectPerson(person) {
        //     const index = this.selectedPeople.findIndex(p => p.id === person.id);
        //     if (index > -1) {
        //         this.selectedPeople.splice(index, 1);
        //     } else {
        //         this.selectedPeople.push({
        //             id: person.id,
        //             name: person.name,
        //             description: person.description,
        //             imageUrl: person.pictureUrl
        //         });
        //     }
        //     console.log('Selected People:', this.selectedPeople);
        // },

        // createPersonAndContinue() {
        //     if (this.newPerson.name || this.newPerson.description || this.newPerson.faces) {
        //         if (!this.validateForm()) {
        //             return;
        //         }

        //         // Find the selected face details
        //         const selectedFace = this.faces.find(face => face.id === this.newPerson.faces);
        //         const faceImageUrl = selectedFace ? selectedFace.background_sas : ''

        //         this.pendingPersons.push({
        //             ...this.newPerson,
        //             imageUrl: faceImageUrl // Add the image URL
        //         }); // Save the new person if form is filled
        //         this.clearNewPerson(); // Clear the form after adding the person
        //     }

        //     if (this.pendingPersons.length > 0) {
        //         // Navigate to "IncidentForm" with pendingPersons
        //         this.$router.push({
        //             name: 'Form', // Ensure this name matches your router configuration
        //             params: { pendingPersons: JSON.stringify(this.pendingPersons) },
        //         });
        //     } else {
        //          this.$error('Please add at least one person before continuing.');
        //     }
        // },
    }
};
</script>
  
<style scoped>
.person-form-container {
    margin: 20px;
}

.people-list .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}

.people-list .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card-img-top {
    width: 100%;
    height: 300px;
    /* Adjust as necessary */
    object-fit: cover;
    /* Ensures the image covers the area properly */
}

.selected-card {
    border: 2px solid green;
}

.selected-face {
    border: 2px solid green;
}

.unselected-face {
    border: 2px solid red;
}
</style>
  